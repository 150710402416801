import type { BaseRouteRpc } from '@repo/contract';
import { FetchError } from 'ofetch';
import { sendShowNotification } from '../composables/notification/notification-emitter';

const INTERNAL_SERVER_ERROR = 500;

export interface FetchRpcOptions {
  successNotificationMessage?: string
};

export async function fetchRpc<T extends typeof BaseRouteRpc>(
  req: Parameters<typeof $fetch>[0],
  body: T['request'],
  {
    successNotificationMessage,
  }: FetchRpcOptions = {},
) {
  try {
    const response = await $fetch<T['response']>(req, {
      method: 'POST',
      body,
    });

    if (successNotificationMessage) {
      sendShowNotification({ type: 'success', message: successNotificationMessage });
    }

    return response;
  }
  catch (e) {
    let notificationMessage = 'Client error occurred.';

    if (e instanceof FetchError) {
      notificationMessage = 'Server error. Please try again.';

      if (e.response?.status !== INTERNAL_SERVER_ERROR) {
        notificationMessage = e.data?.message ?? notificationMessage;
      }

      console.log('FetchError: ', e.data, e.message, e.response);
    }
    else {
      console.error(e);
    }

    sendShowNotification({ type: 'error', message: notificationMessage });

    throw e;
  }
}
